import axios from 'axios';

let $http;

export const init = () => {
    $http = axios.create({
        baseURL: process.env.VUE_APP_SERVICE_URL
    });
    return $http;
};

export const query = (resource, params) => {
    return $http
        .get(`${resource}`, {
            params: params
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
};

export const get = (resource, id = '') => {
    return $http.get(`${resource}/${id}`).catch(error => {
        console.error(error);
        throw error;
    });
};
export const post = (resource, body, headers = undefined) => {
    return $http.post(`${resource}`, body, headers).catch(error => {
        console.error(error);
        throw error;
    });
};
export const update = (resource, id, params) => {
    return $http.put(`${resource}/${id}`, params).catch(error => {
        console.error(error);
        throw error;
    });
};
export const put = (resource, params, headers) => {
    return $http.put(`${resource}`, params, headers).catch(error => {
        console.error(error);
        throw error;
    });
};
export const remove = (resource, id) => {
    return $http.delete(`${resource}/${id}`).catch(error => {
        console.error(error);
        throw error;
    });
};
export const erase = (resource, headers) => {
    return $http.delete(`${resource}`, headers).catch(error => {
        console.error(error);
        throw error;
    });
};
export {$http};
