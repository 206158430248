import {loadStripe} from '@stripe/stripe-js';
import {post} from '@/services/ApiService';
import swal from 'sweetalert2';

export const initStripe = async () => {
    const publishableToken = window.sessionStorage.getItem('paymentDetailsToken');
    if (!publishableToken) {
        swal.fire({
            icon: 'error',
            title: 'Setup Error',
            text: 'There is an error with the stripe setup. Please contact the head office.'
        });
    } else {
        const clientAccountId = window.sessionStorage.getItem('clientAccountId');

        if (clientAccountId) {
            return await loadStripe(publishableToken, {
                stripeAccount: clientAccountId
            });
        } else {
            return await loadStripe(publishableToken);
        }
    }
};

export const processPayment = async cardDetails => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await post('api/payments/register-card?source=Web', cardDetails);
            resolve(result.data);
        } catch (err) {
            reject(err);
        }
    });
};
